'use strict';

import $ from 'jquery';

export default class Anchors {
	constructor($anchor) {
		this.anchorLinks = $('.anchors__navigation', $anchor);

		let anchorClicked = false;

		// Create anchors links
		let _anchorHTML = `<ul>`;

		$('.content-body > section').map((i,ele) => {
			let label = $(ele).find('h2').text(),
				anchorText = $(ele).attr('id');

			_anchorHTML += `<li><a href="#${anchorText}">${label}</a></li>`;
		});

		_anchorHTML += `</ul>`;

		this.anchorLinks.append(_anchorHTML);

		// On scroll handlers
		let anchorLinksHeight = this.anchorLinks.height();
		let footerPoint = $('.site-footer').offset().top - anchorLinksHeight - 50;

		$(window).on('load scroll', (e) => {
			// Make anchors sticky when it reaches the top of the screen
			if(!anchorClicked){
				if ($(window).scrollTop() >= $anchor.offset().top) {
					if ($(window).scrollTop() < footerPoint){
						if(!this.anchorLinks.hasClass('sticky')) {
							this.anchorLinks.addClass('sticky');
							this.anchorLinks.before(`<div class="anchor-spacer" style="height: ${anchorLinksHeight}px;"></div>`);
						}
					}
					else {
						if(this.anchorLinks.hasClass('sticky')) {
							this.anchorLinks.removeClass('sticky');
							$('.anchor-spacer').remove();
						}
					}
				}
				else {
					if(this.anchorLinks.hasClass('sticky')) {
						this.anchorLinks.removeClass('sticky');
						$('.anchor-spacer').remove();
					}
				}

				// Set active anchors based on nearest widget to the top
				$('.content-body > section').map((i, ele) => {
					let $targetAnchor = $(`a[href="#${$(ele).attr('id')}"]`);

					if ($(window).scrollTop() >= $(ele).offset().top - anchorLinksHeight - 20 && $(window).scrollTop() < $(ele).offset().top - anchorLinksHeight - 20 + ($(ele).height() / 2.5)) {
						if (!$(`a[href="#${$(ele).attr('id')}"]`).parent().hasClass('active')) {
							this.setActive($targetAnchor.parent(), this.anchorLinks, i);
						}
						else {
							return;
						}
					}
					else {
						return;
					}
				});
			}
		});

		$('a', this.anchorLinks).map((i, ele) => {
			let target = $(ele).attr('href');

			$(ele).on('click', (e) => {
				e.preventDefault();
				anchorClicked = true;

				if ($(ele).parent().hasClass('active')) {
					return;
				}
				else {
					this.setActive($(ele).parent(), this.anchorLinks, i);
				}

				let $target = $(target),
					offset = anchorLinksHeight + 10;

				$('html, body').stop(true, true).animate({
					scrollTop: $target.offset().top - offset
				}, 500, () => {
					anchorClicked = false;
				});
			});
		});
	}

	setActive($ele, $anchors, i){
		$anchors.find('.active').removeClass('active');
		$ele.addClass('active');

		let offset = $ele.width() * i,
			halfWidth = $ele.parent().width() / 2,
			halfEleWidth = $ele.width() / 2;

		$ele.parent().stop(true, true).animate({
			scrollLeft: offset - halfWidth + halfEleWidth
		}, 500);
	}
}
