'use strict';

import $ from 'jquery';
import queryString from 'query-string';

export default class Searchbar {
	constructor() {
		this.$searchbar = $('.site-header__searchbar');
		this.$input = $('input[type="search"]', this.$searchbar);
		this.$submit = $('button', this.$searchbar);

		this.url = this.$searchbar.data('searchurl');

		this.$submit.on('click', (e) => {
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			this.submitSearch();
		});

		this.$input.keyup((e) => {
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			if (e.keyCode == 13 || e.which == 13) { // enter key maps to keycode `13`
				this.submitSearch();
			}
		});
	}

	getQuery(pageURL, value) {
		return pageURL + '?q=' + encodeURIComponent(value);
	}

	submitSearch() {
		let searchValue = this.$input.val();

		window.location.href = this.getQuery(this.url, searchValue);
	}
}
