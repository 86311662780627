'use strict';

import $ from 'jquery';

export default class ListingSearch {
	constructor($searchbar) {
		this.$searchbar = $searchbar;
		this.$input = $('input[type="search"]', this.$searchbar);
		this.$submit = $('button', this.$searchbar);

		this.$submit.on('click', (e) => {
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			this.submitSearch();
		});

		this.$input.keyup((e) => {
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			if (e.keyCode == 13 || e.which == 13) { // enter key maps to keycode `13`
				this.submitSearch();
			}
		});
	}

	getQuery(value) {
		return '?q=' + encodeURIComponent(value);
	}

	submitSearch() {
		let searchValue = this.$input.val();

		window.location.href = this.getQuery(searchValue);
	}
}
