'use strict';

import $ from 'jquery';

export default class Tab {
	constructor($object) {
		this.tabNavigation = $object.find('[data-tabnav]');
		this.tabContents = $object.find('[data-tabcontents]');

		// // Match width for tab nav items
		// let width = $object.width(),
		// 	numberOfTabs = $('li', this.tabNavigation).length;

		// let navItemWidth = width / numberOfTabs;

		// if(navItemWidth < 150) {
		// 	navItemWidth = 150;
		// }

		// // Set nav item width
		// $('li', this.tabNavigation).map((i, ele) => {
		// 	$(ele).width(navItemWidth);
		// });

		// Tab functionality
		$('li a', this.tabNavigation).map((i, ele) => {
			let target = $(ele).attr('href'),
				$target = $(`${target}`, this.tabContents);

			$(ele).on('click', (e) => {
				e.preventDefault();

				if(!$target.hasClass('active')){
					// Remove 'active' class from current active tab
					$('li.active', this.tabNavigation).removeClass('active');
					// Add 'active' class to clicked tab
					$(ele).parent().addClass('active');

					// Fade out any active content
					$('div.active', this.tabContents).fadeOut(300, function () {
						// Then remove 'active' class from previously active content
						$('div.active', this.tabContents).removeClass('active');

						// Fade in target and attach 'active' class
						$target.fadeIn(300);
						$target.addClass('active');
					});
				}
				else {
					return;
				}
			});
		});
	}
}
