'use strict';

import $ from 'jquery';

export default class MapWidget {
	constructor() {
		this.mapWidget = $('.map-widget');
		this.pinCollection = $('.map-widget__pins', this.mapWidget);

		$('.pin-item', this.pinCollection).map((i, ele) => {
			let $pin = $('.pin-item__pin', $(ele)),
				target = $pin.attr('href'),
				$closeButton = $('.close-button', $(ele));

			$('.pin-item__popup', $(ele)).on('click', (e) => {
				e.preventDefault();
				e.stopPropagation();
			});

			$('.pin-item__popup a', $(ele)).on('click', (e) => {
				e.stopPropagation();
			});

			$pin.on('click', (e) => {
				e.preventDefault();
				e.stopPropagation();

				if(!$pin.parent().hasClass('active')){
					// Close existing active popup
					$('.active', this.pinCollection).removeClass('active');

					// Set this to active and show popup
					$pin.parent().addClass('active');
				}
				else {
					$pin.parent().removeClass('active');
				}
			});

			$closeButton.on('click', function(e) {
				e.preventDefault();
				e.stopPropagation();

				$(this).parent().parent().removeClass('active');
			});
		});

		// $(window).on('click touchstart', () => {
		// 	$('.active', this.pinCollection).removeClass('active');
		// });

		if($('.rdContent').length) {
			this.generateGrid();
		}

		$(window).on('load resize', () => {
			this.calculatePopupPosition(this.mapWidget);
		});
	}

	calculatePopupPosition($scope){
		$('.pin-item').map((i, ele) => {
			let $popup = $('.pin-item__popup', $(ele));

			// Reset popup position
			$popup.css({
				'transform': `translate(-50%, 0)`,
				'right': 'auto',
				'left': '50%'
			});

			// Calculate position
			let popupWidth = $popup.outerWidth(),
				pinOffsetLeft = $('a', $(ele)).offset().left,
				pinWidth = $('a', $(ele)).outerWidth(),
				halfPinWidth = $('a', $(ele)).outerWidth() / 2;

			if ($popup.offset().left < 0) {
				let offset = pinOffsetLeft + halfPinWidth - 10;

				$popup.css({
					'transform': `translate(-${offset}px, 0px)`
				});
			}

			if (($popup.offset().left + popupWidth) > $(window).width()) {
				let pinOffsetRight = $(window).width() - pinOffsetLeft - pinWidth,
					offset = pinOffsetRight + halfPinWidth - 10;

				$popup.css({
					'transform': `translate(${offset}px, 0px)`,
					'right': '50%',
					'left': 'auto'
				});
			}
		});
	}

	generateGrid() {
		let _horizontalLine = '';
		let _verticalLine = '';

		for(let i = 0; i <= 100; i++){
			if(i % 5 == 0) {
				_horizontalLine += `<div class="horizontal-line" style="top: ${i}%;"></div>`;
				_verticalLine += `<div class="vertical-line" style="left: ${i}%;"></div>`;
			}
		}

		let _gridContainer = `<div class="grids-container">${_horizontalLine}${_verticalLine}</div>`;

		$('.map-widget__image').append(_gridContainer);
	}
}
