'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class MediaListingPopup {
	constructor($mediaListing) {
		this.popup = $('.album-carousel', $mediaListing);
		this.carouselImages = $('.album-carousel__images', this.popup);
		this.carouselNav = $('.album-carousel__nav', this.popup);
		
		const albumId = this.popup.attr('id');

		this.carouselImages.on('init', function () {
			onPrint();
			setImageURL(albumId);

			let filesize = $(`#${albumId} .album-carousel__images .slick-active`).find('img').data('filesize');

			$(`#${albumId}`).find('.album-carousel__header a span').text(`Download jpg (${filesize})`);
		});

		this.carouselImages.on('afterChange', (event, slick, currentSlide, nextSlide) => {
			setImageURL(albumId);
		});

		this.carouselImages.slick({
			lazyLoad: 'ondemand',
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			asNavFor: '.album-carousel__nav',
			prevArrow: '<button type="button" class="slick-prev prev"><i class="icon-chevron-left"></i></button>',
			nextArrow: '<button type="button" class="slick-next next"><i class="icon-chevron-right"></i></button>'
		});

		this.carouselNav.slick({
			slidesToShow: 5,
			slidesToScroll: 1,
			asNavFor: '.album-carousel__images',
			arrows: false,
			dots: false,
			centerMode: true,
			centerPadding: '140px',
			focusOnSelect: true,
			responsive: [
				{
					breakpoint: 1366,
					settings: {
						slidesToShow: 7,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 5,
					}
				}
			]
		});

		this.carouselImages.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			let $thisSlide = $(`#${albumId} .album-carousel__images [data-slick-index='${nextSlide}']`),
				// caption = $thisSlide.find('img').data('caption'),
				filesize = $thisSlide.find('img').data('filesize'),
				filename = $thisSlide.find('img').data('filename');

			$(`#${albumId}`).find(`.album-carousel__header .image-title span`).text(filename);
			$(`#${albumId}`).find(`.album-carousel__header a span`).text(`Download jpg (${filesize})`);
			// $(`#${albumId}`).find('.album-carousel__caption p').text(caption);
		});

		function setImageURL(albumid){
			let imageURL = $(`#${albumid} .album-carousel__images .slick-active img`).attr('src');

			$(`#${albumid} .album-carousel__header > a`).attr('href', imageURL);
		}

		function onPrint(){
			let beforePrint = function () {
				console.log('Functionality to run before printing.');
				$('.album-carousel__images').slick('setPosition');
				$('.album-carousel__nav').slick('setPosition');
			};

			let afterPrint = function () {
				console.log('Functionality to run after printing');
				$('.album-carousel__images').slick('setPosition');
				$('.album-carousel__nav').slick('setPosition');
			};

			if (window.matchMedia) {
				let mediaQueryList = window.matchMedia('print');

				mediaQueryList.addListener(function (mql) {
					if (mql.matches) {
						beforePrint();
					} else {
						afterPrint();
					}
				});
			}

			window.onbeforeprint = beforePrint;
			window.onafterprint = afterPrint;
		}
	}
}
