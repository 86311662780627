'use strict';

import $ from 'jquery';
import 'slick-carousel';
import enquire from 'enquire.js';

export default class Carousel {
	constructor() {
		let baseOptions = {
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 600
		};

		// Home lifeite Carousel
		if ($('.home-lifeite').length) {
			// // Bind onPrint on carousel initialisation
			$('.home-lifeite [data-carousel="images"]').on('init', function () {
				onPrint($(this));
			});

			$('.home-lifeite [data-carousel="text"]').on('init', function () {
				onPrint($(this));
			});

			// Home Life@ITE Carousel (Images)
			let lifeITEImages = {
				arrows: false,
				dots: true,
				appendDots: '.home-lifeite__carousel--dots',
				fade: true,
				autoplaySpeed: 5000,
				autoplay: true,
				asNavFor: '.home-lifeite__carousel--text'
			};

			lifeITEImages = $.extend({}, baseOptions, lifeITEImages);
			$('.home-lifeite [data-carousel="images"]').slick(lifeITEImages);

			// Home Life@ITE Carousel (Texts)
			let lifeITEText = {
				autoplay: false,
				// autoplaySpeed: 5000,
				arrows: true,
				prevArrow: '<button type="button" class="slick-prev" aria-label="previous" title="previous"><i class="icon-chevron-left"></i></button>',
				nextArrow: '<button type="button" class="slick-next" aria-label="next" title="next"><i class="icon-chevron-right"></i></button>',
				appendArrows: '.home-lifeite__carousel--text',
				// adaptiveHeight: true,
				fade: true,
				asNavFor: '.home-lifeite__carousel--images'
			};

			lifeITEText = $.extend({}, baseOptions, lifeITEText);
			$('.home-lifeite [data-carousel="text"]').slick(lifeITEText);
		}

		// Home lifeite Carousel
		if ($('.course-testimonial').length) {
			// // Bind onPrint on carousel initialisation
			$('.course-testimonial [data-carousel]').on('init', function () {
				onPrint($(this));
			});

			// Home Life@ITE Carousel (Images)
			let courseTestimonial = {
				arrows: true,
				prevArrow: '<button type="button" class="slick-prev"><i class="icon-chevron-left"></i></button>',
				nextArrow: '<button type="button" class="slick-next"><i class="icon-chevron-right"></i></button>',
				dots: true,
				fade: true,
				rows: 0
			};

			courseTestimonial = $.extend({}, baseOptions, courseTestimonial);
			$('.course-testimonial [data-carousel]').slick(courseTestimonial);
		}

		function onPrint($carousel){
			let beforePrint = function () {
				console.log('Functionality to run before printing.');
				$carousel.slick('setPosition');
			};

			let afterPrint = function () {
				console.log('Functionality to run after printing');
				$carousel.slick('setPosition');
			};

			if (window.matchMedia) {
				let mediaQueryList = window.matchMedia('print');

				mediaQueryList.addListener(function (mql) {
					if (mql.matches) {
						beforePrint();
					} else {
						afterPrint();
					}
				});
			}

			window.onbeforeprint = beforePrint;
			window.onafterprint = afterPrint;
		}
	}
}
