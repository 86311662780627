'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class PersonaOptions {
	constructor() {
		let $personaOptions = $('.persona-options'),
			$toggleButton = $('> button', $personaOptions),
			$options = $('> ul > li > a', $personaOptions);

		// Show/hide options in mobile
		$('button', $personaOptions).on('click', function(e){
			e.stopPropagation();
			e.preventDefault();

			// let optionsOffset = $personaOptions.find('ul').offset().top,
			// 	optionsHeight = $personaOptions.find('ul').outerHeight(),
			// 	optionsBaseOffset = optionsOffset + optionsHeight,
			// 	browserHeight = $(window).height();

			// if ($(window).scrollTop() + browserHeight + 30 < optionsBaseOffset) {
			// 	if(!$personaOptions.hasClass('reverse')){
			// 		$personaOptions.addClass('reverse');
			// 	}
			// } else {
			// 	$personaOptions.removeClass('reverse');
			// }

			if(!$personaOptions.hasClass('active')){
				$personaOptions.addClass('active');
			}
			else {
				$personaOptions.removeClass('active');
			}
		});

		$(window).on('click', () => {
			$personaOptions.removeClass('active');
		})

		let optionsOffset = $personaOptions.find('ul').offset().top,
			optionsHeight = $personaOptions.find('ul').outerHeight(),
			optionsBaseOffset = optionsOffset + optionsHeight,
			browserHeight = $(window).height();

		$(window).on('load scroll', () => {
			if ($(window).scrollTop() + browserHeight + 30 < optionsBaseOffset) {
				if(!$personaOptions.hasClass('reverse')){
					$personaOptions.addClass('reverse');
				}
			} else {
				$personaOptions.removeClass('reverse');
			}
		});

		enquire.register("screen and (max-width: 1023px)", {
			match: () => {
				if ($personaOptions.hasClass('active')) {
					$personaOptions.removeClass('active');
				}
			},
			unmatch: () => {
				$personaOptions.removeClass('active');
			}
		});

		// Tab function
		$options.map((i, ele) => {
			$(ele).on('click', function(e){
				e.preventDefault();

				let target = $(this).attr('href'),
					text = $(this).find('span').text();

				// Change text in toggle button
				$toggleButton.html(`${text}<i class="icon-wide-chevron"></i>`);
				$personaOptions.removeClass('active');

				$('.persona-options > ul > .active').removeClass('active');
				$(this).parent().addClass('active');

				// Switch content
				if ($('.persona-links .active').length){
					$('.persona-links .active').slideUp(300, function(){
						$('.persona-links .active').removeClass('active');

						$(`.persona-links [data-category="${target}"]`).slideDown(300);
						$(`.persona-links [data-category="${target}"]`).addClass('active');
					});
				}
				else {
					$(`.persona-links [data-category="${target}"]`).slideDown(300);
					$(`.persona-links [data-category="${target}"]`).addClass('active');
				}

				$('.home-banner-item.active').fadeOut(300, function () {
					$('.home-banner-item.active .home-banner-item__links').removeClass('slidein');
					$(`.home-banner-item[data-category="${target}"] .home-banner-item__links > ul > li`).removeClass('fadein');
					$('.home-banner-item.active').removeClass('active');


					$(`.home-banner-item[data-category="${target}"]`).fadeIn(300, function(){
						$(`.home-banner-item[data-category="${target}"]`).addClass('active');
						$(`.home-banner-item[data-category="${target}"] .home-banner-item__links`).addClass('slidein');

						let interval = 350;

						$(`.home-banner-item[data-category="${target}"] .home-banner-item__links > ul > li`).map((i, ele) => {
							let $this = $(ele);

							setTimeout(function () {
								$this.addClass('fadein');
							}, interval);

							interval += 45;
						});
					});
				});

				if($('.home-banner').hasClass('on-load')){
					$('.home-banner').removeClass('on-load');
				}
			});
		});
	}
}
