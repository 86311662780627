'use strict';

import $ from 'jquery';

export default class Sorter {
	constructor(runConstructor = true) {
		if (runConstructor) {
			let $dropdown = $('.dropdown');

			$dropdown.map((i, ele) => {
				let $this = $(ele),
					$selected = $('.selected', $this);

				$selected.on('click', function (e) {
					e.preventDefault();
					e.stopPropagation();

					let $options = $(this).next();

					if(!$options.hasClass('opened')){
						$options.addClass('opened');
					}
					else {
						$options.addClass('closed');

						$options.removeClass('opened');
						setTimeout(function(){
							$options.removeClass('closed');
						}, 300);
					}
				});

				$(window).on('click', function(){
					if ($('.dropdown__options ul', $this).hasClass('opened')) {
						$('.dropdown__options ul', $this).addClass('closed');
						$('.dropdown__options ul', $this).removeClass('opened');

						setTimeout(function () {
							$('.dropdown__options ul', $this).removeClass('closed');
						}, 300);
					}
				});
			});
		}
	}

	getSorterValue($sorter){
		return $('.selected', $sorter).data('value');
	}
}
