'use strict';

import $ from 'jquery';

export default class TableResponsive {
	constructor() {
		let $table = $('table');

		$(window).on('resize.tableResponsive', () => {
			$table.map((i, ele) => {
				let $this = $(ele);

				if (!$this.closest('.table-responsive').length) {
					if ($this.width() > $this.parent().width()) {
						$this.wrap('<div class="table-responsive"></div>');
					}
				}
			});
		}).trigger('resize.tableResponsive');
	}
}
