'use strict';

import $ from 'jquery';

export default class BackToTop {
	constructor() {
		let $backToTop = $('.back-to-top');

		let triggerPoint = $(document).height() * 0.1;

		$(window).on('scroll', function(){
			if($(window).scrollTop() > triggerPoint){
				$('.back-to-top').addClass('fadein');
			}
			else {
				$('.back-to-top').removeClass('fadein');
			}
		});

		$backToTop.on('click', function(e) {
			e.preventDefault();
			$('html, body').animate({scrollTop: 0}, 450, "swing", function(){});
		});
	}
}
