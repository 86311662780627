'use strict';

import $ from 'jquery'

export default class AccordionList {
  constructor($selector, selfInit = true) {
    if (selfInit){
      this.init($selector);
    }
  }

  init($selector){
    const $accordion = $(".accordion", $selector);
    $("#collpaseAll", $selector).on('click', (e) => {
      $(".accordion__content", $accordion).slideUp();
      $accordion.removeClass('is-active');
    })

    $("#expandAll", $selector).on('click', (e) => {
      $(".accordion__content", $accordion).slideDown();
      $accordion.addClass('is-active');
    })

    $(".accordion__content", $accordion).first().slideDown();
    $accordion.first().addClass('is-active');

  }
}
