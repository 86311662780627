export default class LazyLoading {
  constructor() {
    let lazyloadImages;    
    if ("IntersectionObserver" in window) {
      lazyloadImages = document.querySelectorAll(".lazy");

      let imageIframeObserver = new IntersectionObserver(function(entries, observer) {
        for (let i = 0; i < entries.length; i++) {
          if (entries[i].isIntersecting) {
            let image = entries[i].target;
            image.src = image.dataset.src;
            image.classList.remove("lazy");
            imageIframeObserver.unobserve(image);
          }
        }
        /* entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            var image = entry.target;
            image.src = image.dataset.src;
            image.classList.remove("lazy");
            imageIframeObserver.unobserve(image);
          }
        }); */
      });
  
      for (let i = 0; i < lazyloadImages.length; i++) {
        imageIframeObserver.observe(lazyloadImages[i]);
      }
      /* lazyloadImages.forEach(function(image) {
        imageIframeObserver.observe(image);
      }); */

      
    } else {  
      let lazyloadThrottleTimeout;
      lazyloadImages = document.querySelectorAll(".lazy");
      
      const getOffsetTop = element => {
        let offsetTop = 0;
        while(element) {
          offsetTop += element.offsetTop;
          element = element.offsetParent;
        }
        return offsetTop;
      }

      function lazyload () {
        if(lazyloadThrottleTimeout) {
          clearTimeout(lazyloadThrottleTimeout);
        }    
  
        lazyloadThrottleTimeout = setTimeout(function() {
          let scrollTop = window.pageYOffset;
          for (let i = 0; i < lazyloadImages.length; i++) {
            if((window.innerHeight + scrollTop) > getOffsetTop(lazyloadImages[i])) {
              lazyloadImages[i].src = lazyloadImages[i].dataset.src;
              lazyloadImages[i].classList.remove('lazy');
            }
          }
          /* lazyloadImages.forEach(function(img) {
              if((window.innerHeight + scrollTop) > img.offsetTop) {
                img.src = img.dataset.src;
                img.classList.remove('lazy');
              }
          }); */
          if(lazyloadImages.length == 0) { 
            document.removeEventListener("scroll", lazyload);
            window.removeEventListener("resize", lazyload);
            window.removeEventListener("orientationChange", lazyload);
          }
        }, 20);
      }
  
      document.addEventListener("scroll", lazyload);
      window.addEventListener("resize", lazyload);
      window.addEventListener("orientationChange", lazyload);
      lazyload(); //to lazyload any images on the first load viewport 
    }
  }
}