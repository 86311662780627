'use strict';

import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesLoaded';

export default class HomeSocial {
	constructor() {
		// make Masonry a jQuery plugin
		jQueryBridget('masonry', Masonry, $);
		jQueryBridget('imagesLoaded', imagesLoaded, $);

		// now you can use $().masonry()
		$('.home-social__items .row').imagesLoaded(function () {
			$('.home-social__items .row').masonry({
				itemSelector: '.home-social-item',
				horizontalOrder: true,
				percentPosition: true
			});
		});

		// Update matched height for print preview before printing
		let beforePrint = function () {
			console.log('Functionality to run before printing - masonry.');
			$('.home-social__items .row').masonry('layout');
		};

		let afterPrint = function () {
			console.log('Functionality to run after printing - masonry');
			$('.home-social__items .row').masonry('layout');
		};

		if (window.matchMedia) {
			let mediaQueryList = window.matchMedia('print');

			mediaQueryList.addListener(function (mql) {
				if (mql.matches) {
					beforePrint();
				} else {
					afterPrint();
				}
			});
		}

		window.onbeforeprint = beforePrint;
		window.onafterprint = afterPrint;
	}
}
