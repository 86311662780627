'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';
import Accordion from '../../utils/accordion/accordion';

export default class SideNav {
	constructor() {
		let $sideNav = $('.side-nav'),
			$level1 = $('.side-nav__level1', $sideNav),
			$toggle = $('.side-nav-toggle', $sideNav);

		$('> li', $level1).map((i, ele) => {
			// Attach accordion functionality for side nav for mobile use
			new Accordion($(ele));
		});

		$toggle.on('click', function(e){
			e.preventDefault();
			e.stopPropagation();

			if (!$(this).parent().hasClass('active')) {
			  	$(this).parent().addClass('active');
			} else {
			  	$(this).parent().removeClass('active');
			}
		});

		$(window).on('load', function () {
			$('.side-nav__level1 li').map((i, ele) => {
				if($(ele).hasClass('active')){
					$(ele).find('[data-content]').slideDown(300);
				}
			});
		});

		$(window).on('click', function () {
			if ($('.side-nav').hasClass('active')) {
				$('.side-nav').removeClass('active');
			}
		});

		enquire.register("screen and (min-width: 768px)", {
			match: () => {
				// Open nav with active on load
				if ($('.side-nav__level2 > .active').length){
					$('.side-nav__level2 > .active').parent().parent().find('i').trigger('click');
				}
			}
		});
	}
}
