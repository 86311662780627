'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';
import Accordion from '../../utils/accordion/accordion';

export default class SiteHeader {
	constructor() {
		let $siteHeader = $('.site-header'),
			$searchToggle = $('.site-header__search-toggle button', $siteHeader),
			$searchBar = $('.site-header__searchbar', $siteHeader),
			$navToggle = $('.site-header__nav-toggle button', $siteHeader),
			$nav = $('.site-header__navigation', $siteHeader),
			$navMenu = $('.site-header__nav', $siteHeader),
			$expandMenuIcon = $('[data-toggle]', $siteHeader);

		let windowWidth = window.innerWidth;

		$searchToggle.on('click', function(e){
			e.preventDefault();

			if(!$(this).parent().hasClass('active')){
				$(this).parent().addClass('active');
				$searchBar.stop(true, false).slideDown(300);
			}
			else {
				$(this).parent().removeClass('active');
				$searchBar.stop(true, false).slideUp(300);
			}
		});

		$navToggle.on('click', function (e) {
			e.preventDefault();

			if (!$siteHeader.hasClass('nav-active')) {
				$('body').addClass('nav-active');
				$siteHeader.addClass('nav-active');
				$(this).parent().addClass('collapse');
				setTimeout(() => {
					$(this).parent().addClass('rotate');
				}, 200);
			}
			else {
				$('body').removeClass('nav-active');
				$siteHeader.removeClass('nav-active');
				$(this).parent().removeClass('rotate');
				setTimeout(() => {
				  	$(this).parent().removeClass('collapse');
				}, 200);
				$('.is-active').next().slideUp();
				$('.is-active').removeClass('is-active');
			}
		});

		$expandMenuIcon.on('click', (e) => {
			if (windowWidth < 1024){
				const $parent = $(e.target).parent();
				const $megamenu = $(e.target).parent().next();

				$parent.toggleClass('is-active');
				$megamenu.slideToggle();
			}
		});

		$(window).on('load', (e) => {
			if($(window).width() > 1024){
				let browserWidth = $(window).width();

				$('.nav__level2', $siteHeader).map((i, ele) => {
					if($(ele).offset().left + ($(ele).width() * 2) > browserWidth - 20){
						$(ele).addClass('flip');
					}
				});
			}
		});

		$(window).on('resize', (e) => {
			windowWidth = window.innerWidth;

			if (window.innerWidth >= 1024){
				setTimeout(()=> {
					$('body').removeClass('nav-active');
					$siteHeader.removeClass('nav-active');
					$navToggle.parent().removeClass('rotate');
					setTimeout(() => {
						$navToggle.parent().removeClass('collapse');
					}, 200);
					$('.is-active').next().slideUp();
					$('.is-active').removeClass('is-active');
				}, 200)
			}
		})
		
		var $level2Listings = $('.megamenu__lvl2').children();	
		$level2Listings.on('mouseover', function(){
			const images = $(this).find('img');
			images.each(function() {
				let src = $(this).attr('src');
				let url = $(this).attr('data-src');
				$(this).attr('src', url);
			}); 
		});
	}
}
