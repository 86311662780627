'use strict';
import $ from 'jquery'

export default class Accordion {
  constructor($selector, selfInit = true) {
    if (selfInit) {
      this.init($selector);
    }
  }

  init($selector){
    const $parent = $selector.closest(".accordion-list");

    $(".accordion__header", $selector).on('click', (e) => {
      const $thisAccordionContent = $(".accordion__content", $selector);

      $selector.toggleClass('is-active');
      $thisAccordionContent.slideToggle();

      $(".accordion__content", $parent).not($thisAccordionContent).slideUp();
      $(".accordion",$parent).not($selector).removeClass('is-active');

    })
  }
}
