'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';
import PersonaOptions from '../persona-options/persona-options';

export default class HomeBanner {
	constructor() {
		let $homeBanner = $('.home-banner'),
			$personaBG = $('.persona-background', $homeBanner),
			$homeBannerItem = $('.home-banner-item', $homeBanner),
			$homeBannerItemLinks = $('.home-banner-item__links', $homeBanner);

		new PersonaOptions();

		// Skew for mobile
		if($(window).width() < 1024) {
			let skew = calcSkew();

			$personaBG.css('transform', `skewY(-${skew}deg)`);
		}
		else {
			if ($(window).width() >= 1280) {
				let rightPosition = calRightPosition(0.41);

				$homeBannerItemLinks.css('right', `${rightPosition}px`);
			}
			else {
				let rightPosition = calRightPosition(0.44);

				$homeBannerItemLinks.css('right', `${rightPosition}px`);
			}
		}

		$(window).on('resize', function(){
			let skew = calcSkew();

			$personaBG.css('transform', `skewY(-${skew}deg)`);

			if ($(window).width() >= 1024) {
				if ($(window).width() >= 1280) {
					let rightPosition = calRightPosition(0.41);

					$homeBannerItemLinks.css('right', `${rightPosition}px`);
				}
				else {
					let rightPosition = calRightPosition(0.44);

					$homeBannerItemLinks.css('right', `${rightPosition}px`);
				}
			}
		});

		function calcSkew() {
			let skewRad,
				skewDeg;

			let windowWidth = parseInt($(window).width());

			skewRad = Math.atan(40 / windowWidth);

			skewDeg = skewRad * 7 / 22 * 180;

			return skewDeg;
		}

		function calRightPosition(percent) {
			let fullWidth = $(window).width();

			let rightPosition = 0 - (fullWidth * percent) - 110;

			return rightPosition;
		}
	}
}
