'use strict';

import $ from 'jquery';
import Accordion from '../../utils/accordion/accordion';
import queryString from 'query-string';

export default class CourseFilters {
	constructor($courseFilter) {
		this.courseFilters = $courseFilter;
		this.resetButton = $('.reset', this.courseFilters);
		this.filterToggle = $('.filter-toggle');
		this.closeFilter = $('.close', this.courseFilters);
		this.applyFilter = $('.submit', this.courseFilters);

		$('.filter-group', this.courseFilters).map((i, ele) => {
			// Attach accordion functionality for side nav for mobile use
			new Accordion($(ele));
		});

		this.resetButton.on('click', (e) => {
			e.preventDefault();

			$('input[type="checkbox"]', this.courseFilters).prop('checked', false);
		});

		this.filterToggle.on('click', (e) => {
			e. preventDefault();

			this.courseFilters.toggleClass('active');
		});

		this.closeFilter.on('click', (e) => {
			e. preventDefault();

			this.courseFilters.removeClass('active');
		});

		// $(window).on('load scroll resize', (e) => {
		// 	if($(window).width() >= 768){
		// 		let filterWidth = this.courseFilters.parent().width();

		// 		if(this.courseFilters.hasClass('sticky')) {
		// 			this.courseFilters.css('width', filterWidth);
		// 		}

		// 		// Make anchors sticky when it reaches the top of the screen
		// 		if ($(window).scrollTop() >= $('.course-finder').offset().top - 35) {		// use course finder as static offset point
		// 			if(!this.courseFilters.hasClass('sticky')) {
		// 				this.courseFilters.addClass('sticky');
		// 				this.courseFilters.before(`<div class="filter-spacer" style="width: ${filterWidth}px; height: 1px;"></div>`);
		// 				this.courseFilters.css('width', filterWidth);
		// 			}
		// 		}
		// 		else {
		// 			if(this.courseFilters.hasClass('sticky')) {
		// 				this.courseFilters.removeClass('sticky');
		// 				this.courseFilters.css('width', '');
		// 				$('.filter-spacer').remove();
		// 			}
		// 		}
		// 	}
		// 	else {
		// 		this.courseFilters.css('width', '');
		// 	}
		// });
	}

	getCategoryFilters($filtergroup){
		let filterValues = '';

		$('input:checked', $filtergroup).map((i, ele) => {
			filterValues += `${$(ele).val()},`;
		});

		filterValues = filterValues.slice(0, -1);

		return filterValues;
	}
}
