'use strict';

import $ from 'jquery';
import CourseFilters from '../course-filters/course-filters';
import Sorter from '../../molecules/sorter/sorter';
import queryString from 'query-string';

export default class CourseFinder {
	constructor($courselisting) {
		this.filters = $('.course-filters');
		this.search = $('.course-search', $courselisting);
		this.sorter = $('.course-sorter', $courselisting);

		let filters = new CourseFilters(this.filters);
		let sorter = new Sorter(false);

		/* Define the search parameters model */
		/* Initialise the value on page load */
		this.searchParameters = {
			q: this.getSearchValue(this.search),
			sortBy: sorter.getSorterValue(this.sorter),
			page: '0'
		};

		$('.filter-group', this.filters).map((i, ele) => {
			let $input = $(ele).find('input[type="checkbox"]');
			let name = $input.attr('name');

			this.searchParameters[name] = filters.getCategoryFilters($(ele), this.filters);
		});

		// Search functionality
		if (location.search) {
			this.loadQueryValues();
		}

		/* Event Listener */
		filters.applyFilter.on('click', (e) => {
			e.preventDefault();

			this.searchParameters.q = this.getSearchValue(this.search);
			this.searchParameters.page = '0';

			$('.filter-group', this.filters).map((i, ele) => {
				let $input = $(ele).find('input[type="checkbox"]');
				let name = $input.attr('name');

				this.searchParameters[name] = filters.getCategoryFilters($(ele), this.filters);
			});

			this.updateQueries();
		});

		$('input[type="search"]', this.search).keyup((e) => {
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			if (e.keyCode == 13 || e.which == 13) { // enter key maps to keycode `13`
				filters.applyFilter.trigger('click');
			}
		});

		$('button', this.search).on('click', (e) => {
			e.preventDefault();

			filters.applyFilter.trigger('click');
		});
	}

	getSearchValue($searchBar) {
		return $('input', $searchBar).val();
	}

	updateQueries() {
		const searchQueryString = queryString.stringify(this.searchParameters, {
			sort: false
		});

		window.location.search = searchQueryString;
	}

	loadQueryValues() {
		const parsed = queryString.parse(location.search, {
			decode: true
		});

		this.searchParameters = parsed;

		for (var key in parsed) {
			if (parsed[key] && key == 'q') {
				// Set value of search input bar
				$('input', this.search).val(parsed[key]);
			}

			if (parsed[key] && key == 'sortBy'){
				$('.course-sorter .selected span').text(parsed[key]);
			}

			if (parsed[key]) {
				let values = parsed[key].split(',');

				if(values.length > 0){
					for (let i = 0; i < values.length; i++) {
						$(`input[name="${key}"]`).map((j, ele) => {
							// console.log(values[i], $(ele).val());
							if($(ele).val() == values[i]){
								$(ele).prop('checked', true);
							}
						});
					}

					$(`.filter-group[data-name="${key}"]`).find('[data-toggle]').trigger('click');
				}
			}
		}
	}
}
