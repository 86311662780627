// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import 'fancybox';
import LazyLoading from './LazyLoading';

import TableResponsive from '../_modules/utils/table-responsive/table-responsive';
// import SelectClone from '../_modules/utils/select-clone/select-clone';
import BackToTop from '../_modules/utils/back-to-top/back-to-top';
import Animations from '../_modules/utils/animations/animations';
import Carousel from '../_modules/utils/carousel/carousel';
import Tab from '../_modules/utils/tab/tab';

import Sorter from '../_modules/molecules/sorter/sorter';
import SideNav from '../_modules/molecules/side-nav/side-nav';
import Anchors from '../_modules/molecules/anchors/anchors';
import Searchbar from '../_modules/molecules/searchbar/searchbar';
import ListingSearch from '../_modules/molecules/listing-search/listing-search';
import Accordion from '../_modules/molecules/accordion/accordion';


import SiteHeader from '../_modules/organisms/site-header/site-header';
import HomeSocial from '../_modules/organisms/home-social/home-social';
import HomeBanner from '../_modules/organisms/home-banner/home-banner';
import MapWidget from '../_modules/organisms/map-widget/map-widget';
import MediaListingPopup from '../_modules/organisms/media-listing-popup/media-listing-popup';
import MediaListing from '../_modules/organisms/media-listing/media-listing';
import CourseFinder from '../_modules/organisms/course-finder/course-finder';
import AccordionList from '../_modules/organisms/accordion-list/accordion-list';


let iteJQuery = $.noConflict(true);

(($) => {

	//Polyfill for object-fit
	objectFitImages();

	// Apply wrapper for table
	if ($('table').length) {
		new TableResponsive();
	}

	new SiteHeader();
	new Searchbar();
	new BackToTop();
	// new SelectClone();

	if ($('.carousel').length) {
		new Carousel();
	}

	if ($('.dropdown').length) {
		// Sorter/filter handlers
		new Sorter();
	}

	if($('.home-banner').length){
		new HomeBanner();
	}

	if($('.accordion').length){
		$('.accordion').map((i, ele) => {
			new Accordion($(ele));
		})
	}

	if($('.accordion-list').length){
		$('.accordion-list').map((i, ele) => {
			new AccordionList($(ele));
		})
	}


	if($('.home-social').length){
		new HomeSocial();
	}

	if ($('.side-nav').length) {
		new SideNav();
	}

	if ($('.listing-search').length) {
		if ($('.listing-search').hasClass('news-search') || $('.listing-search').hasClass('site-search')) {
			new ListingSearch($('.listing-search'));
		}
	}

	new Animations();

	if ($('.tabs').length) {
		$('.tabs').map((i,ele) => {
			new Tab($(ele));
		});
	}

	if($('.map-widget').length){
		new MapWidget();
	}

	if($('.media-listing').length){
		$('.media-listing').map((i,ele) => {
			console.log(i);
			new MediaListing($(ele));
			new MediaListingPopup($(ele));
		});
	}

	if($('.course-finder').length){
		$('.course-finder').map((i,ele) => {
			new CourseFinder($(ele));
		});
	}

	if ($('.anchors').length) {
		$('.anchors').map((i, ele) => {
			new Anchors($(ele));
		});
	}

	// Elements with match height
	$('.match-height').matchHeight();
	if ($('.listing-item').length) {
		$('.listing-item h3.match-height').matchHeight();
	}

	// Update matched height for print preview before printing
	let beforePrint = function () {
		console.log('Functionality to run before printing - match height');
		$('.match-height').matchHeight._update();
		$('.listing-item h3.match-height').matchHeight._update();
	};

	let afterPrint = function () {
		console.log('Functionality to run after printing - match height');
		$('.match-height').matchHeight._update();
		$('.listing-item h3.match-height').matchHeight._update();
	};

	if (window.matchMedia) {
		let mediaQueryList = window.matchMedia('print');

		mediaQueryList.addListener(function (mql) {
			if (mql.matches) {
				beforePrint();
			} else {
				afterPrint();
			}
		});
	}

	if ($('.match-height').length) {
		window.onbeforeprint = beforePrint;
		window.onafterprint = afterPrint;
	}

	if ($('.lazy').length) {
		new LazyLoading();
	}

})(iteJQuery);
