'use strict';

import $ from 'jquery';

export default class Accordion {
	constructor($object) {
		let $toggle = $('> [data-toggle]', $object),
			$content = $toggle.next();

		$toggle.on('click', function(e){
			e.stopPropagation();

			if (!$(this).parent().hasClass('active')){
				$(this).parent().addClass('active');
				$content.slideDown(300);
			}
			else {
				$(this).parent().removeClass('active');
				$content.slideUp(300);
			}
		});
		// if(type == 'sidenav'){
		// }
		// else {
		// 	$toggle.on('click', function (e) {
		// 		e.preventDefault();
		// 		e.stopPropagation();

		// 		if (!$(this).parent().hasClass('active')){
		// 			$(this).parent().addClass('active');
		// 			$content.slideDown(300);
		// 		} else {
		// 			$(this).parent().removeClass('active');
		// 			$content.slideUp(300);
		// 		}
		// 	});
		// }
	}
}
