'use strict';

import $ from 'jquery';

export default class MediaListing {
	constructor($mediaListing) {
		this.mediaListing = $mediaListing;

		const albumid = $('.album-carousel', this.mediaListing).attr('id');

		$('.media-listing-item [data-popup]', this.mediaListing).on('click', function (e) {
			e.preventDefault();

			let target = $(this).attr('href'),
				index = $(this).data('index');

			// Print styles
			$(`#${albumid} .album-carousel__images`).on('init', function () {
				onPrint($(this));
			});

			$(`#${albumid} .album-carousel__images`).slick('slickGoTo', index);

			setTimeout(function(){
				$(target).addClass('active');
			}, 150);
		});

		$(`#${albumid} .album-carousel__close button`).on('click', function (e) {
			e.stopPropagation();
			$(`#${albumid}`).removeClass('active');
		});

		$(`#${albumid}`).on('click', function (e) {
			e.stopPropagation();
			$(this).removeClass('active');
		});

		$(`#${albumid} .album-carousel__wrapper`).on('click', function (e) {
			e.stopPropagation();
		});

		function onPrint($carousel){
			let beforePrint = function () {
				console.log('Functionality to run before printing.');
				$carousel.slick('setPosition');
			};

			let afterPrint = function () {
				console.log('Functionality to run after printing');
				$carousel.slick('setPosition');
			};

			if (window.matchMedia) {
				let mediaQueryList = window.matchMedia('print');

				mediaQueryList.addListener(function (mql) {
					if (mql.matches) {
						beforePrint();
					} else {
						afterPrint();
					}
				});
			}

			window.onbeforeprint = beforePrint;
			window.onafterprint = afterPrint;
		}
	}
}
