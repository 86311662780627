'use strict';

import $ from 'jquery';

export default class Animations {
	constructor() {
		let $animatedModule = $('[data-animate]'),
			$cta = $('[data-cta="rollin"]');

		$(window).on('load scroll', function () {
			$animatedModule.map((i, ele) => {
				let offsetTop = $(ele).offset().top,
					scrollTrigger = $(window).height() * 0.75;

				let	$animateItem = $('[data-animation]', $(ele)),
					animationClass = $animateItem.data('animation');

				if (($(window).scrollTop() + scrollTrigger) > offsetTop) {
					let interval = 150;

					$animateItem.map((j, ele) => {
						let $this = $(ele);

						setTimeout(function () {
							$this.addClass(animationClass);
						}, interval);

						interval += 100;
					});
				}
			});

			$cta.map((i, cta) => {
				let ctaInterval = 0;
				let offsetTop = $(cta).offset().top,
					scrollTrigger = $(window).height() * 0.75;

				if (($(window).scrollTop() + scrollTrigger) > offsetTop) {
					setTimeout(function () {
						$(cta).addClass('rollin');
					}, ctaInterval);

					ctaInterval += 200;
				}
			});
		});
	}
}
